import { computed } from 'vue';
import { useStore } from 'vuex';
import { StateInterface } from '@/store';

export const useWorksStore = () => {

  const store = useStore<StateInterface>();

  return {
    works: computed( () => store.state.works.dataList),
    work: computed( () => store.state.works.dataOne),
    //Getters
    isWorksReady: computed<boolean>( () => store.getters['works/isWorkReady']),
    //Actions
    getAllWorks: () => store.dispatch('works/getAllWorks'),
    getWorkById: (id: string) => store.dispatch('works/getWorkById', id),
    dropWork: () => store.dispatch('works/dropWork'),
  };

}