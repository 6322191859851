import { onMounted, ref } from 'vue';
import type { Ref } from 'vue';

export function useNearScreen(): [Ref<boolean>, Ref<HTMLInputElement | null>] {
    const element = ref<HTMLInputElement | null>(null);
    const show    = ref<boolean>(false);

    onMounted(() => {
        Promise.resolve<any>(  // Cargar solo cuando el navagador no lo soporta
          typeof window.IntersectionObserver === 'undefined'
          ? import('intersection-observer')
          : window.IntersectionObserver
        )
        .then(() => {
          const observer = new window.IntersectionObserver(function (entries) {// Recibe todas las entras que estan en el viewport y que esta obsevando
            const { isIntersecting } = entries[0] // isIntersecting nos indica si esta en el viewport del usuario
            if (isIntersecting) {
              show.value = true;
              observer.disconnect()
            }
          })
          if (element.value)
            observer.observe(element.value)// invocar el observer y le pasamos el elemento q desea observar
        })
    }); //Ejecutar solo cuando cambie la referencia

    return [show, element]
}
