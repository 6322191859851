<template>
  <h1 v-if="as==='h1'" class="title-component h1" :class="size">
    <slot>Escriba el título</slot>
  </h1>
  <h2 v-if="as==='h2'" class="title-component h2" :class="size">
    <slot>Escriba el título</slot>
  </h2>
</template>

<script>
export default {
  props: {
    as: {
      type: String,
      default: 'h1'
    },
    size: {
      type: String,
      default: 'lg'
    },
  }
};
</script>

<style lang="sass" scoped>
.title-component
  &.h1
  @at-root h1#{&}
    text-transform: uppercase
    @include font-style($roboto-condensed, 700, 30px, 40px, 0px, $color-primary-dark)
    @include media(tablet-md-max)
      font-size: 28px
    &.xl
      font-size: 48px
      @include media(tablet-md-max)
        font-size: 36px
  &.h2
  @at-root h2#{&}
    @include font-style($roboto-condensed, 700, 30px, 40px, 0px, $color-primary-dark)
    @include media(tablet-md-max)
      font-size: 24px
</style>
