<template>
  <div class="loader">
    <slot />
  </div>
</template>

<style lang="sass" scoped>
.loader
    @include skeleton-common-style
    height: 100%
    width: 100%
    position: absolute
    top: 0
</style>
