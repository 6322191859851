import { computed, defineComponent } from 'vue';
import LoaderPhotos from './LoaderPhotos.vue';
import { useNearScreen } from '@shared/composables/userNeatScreen';

const DEFAULT_IMAGE = 'https://res.cloudinary.com/midudev/image/upload/w_300/q_80/v1560262103/dogs.png';

export default defineComponent({
  name: 'PhotoCard',
  emits: ['on:click'],
  props: {
    src: {
      type: String,
      default: DEFAULT_IMAGE
    },
    alt: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    class: String
  },
  components: { LoaderPhotos },
  setup(props) {
    const [show, element] = useNearScreen();

    const onFailureLoadImage = (e: any) => {
      if (e.currentTarget) {
        e.currentTarget.src = DEFAULT_IMAGE;
      }
    }
    
    return {
      element,
      srcImage: computed(() => props.src),
      altImage: computed(() => props.alt),
      isLoading: computed(() => !show.value || props.loading),
      className: computed(() => props.class),
      onFailureLoadImage
    };
  }
});